<template>
  <div class="home">
    <div id="page-hero" class="pt-6 pb-2 py-md-12 white--text container--fluid">
      <div class="container">
        <v-row>
          <v-col cols="12" sm="12">
            <h1 class="display-2 mb-4">{{ tenant.name }}</h1>
            <p>
              {{ tenant.description }}
            </p>
            <div>
              <v-btn class="mr-4 mb-4" color="white" :to="{name:'events'}">{{ $t('events.explore-challenges') }}</v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-container class="mb-12">
      <v-row>
        <v-col>
          <h3 class="title">{{ $t('events.upcoming-challenges') }}</h3>
          <EventGrid :items="events" class=""></EventGrid>
        </v-col>
      </v-row>
    </v-container>

    <div class="my-12">&nbsp;</div>
  </div>
  
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import eventService from "@/services/eventService";
import EventGrid from "@/components/EventGrid.vue";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "home",
  components: {
    EventGrid,
  },
  data() {
    return {
      tenant: tenant,
      events: null,
    };
  },
  async mounted() {
    this.events = (await eventService.upcoming()).data.data;

  }, 
};
</script>

<style lang="scss" scoped>
  .features {
    i { background-color: #F4652422; border-radius: 50%; padding: 20px; margin-top: 20px; }
    .plus {
      margin: 0 20px; line-height: 40px;
      color: #F46524;
    }
    h4 { margin-top: 8px; }
  }
</style> 